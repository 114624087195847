import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FeatureFlagsService } from '../services/feature-flags.service';

export function authGuard(): CanActivateFn {
    return async () => {
        const authService = inject(AuthService);
        const featureFlagsService = inject(FeatureFlagsService);
        const router = inject(Router);
        const document = inject(DOCUMENT);

        if (!authService.isLoggedIn) {
            if (featureFlagsService.useDotNetBackEnd) {
                await router.navigateByUrl('/auth/login');
                return false;
            } else {
                document.location.href = environment.rubyPortalBaseUrl;
                return false;
            }
        }

        return true;
    };
}
