<div class="top-bar d-lg-none">
    <img class="icon" src="assets/images/logo.svg" />
    <button class="btn text-white" type="button" (click)="toggleMenu()">
        <i class="pi pi-bars"></i>
    </button>
</div>
<div class="site-layout">
    <div
        class="side-bar offcanvas-lg offcanvas-end bg-primary"
        [class.shrinked]="sidebarShrinked"
        [class]="menuClass">
        <div class="d-flex justify-content-between align-items-center">
            <img class="icon" src="assets/images/logo.svg" />
            <button
                class="btn text-white d-lg-none"
                type="button"
                (click)="toggleMenu()">
                <i class="pi pi-times"></i>
            </button>
        </div>
        <!-- TODO: Search bar Component  -->
        <div class="search">
            <input
                type="text"
                placeholder="Search"
                class="form-control search-control" />
        </div>
        <app-side-bar-menu></app-side-bar-menu>
        <div class="d-flex justify-content-between align-items-center">
            <span class="user-dropdown fs-6" (click)="menu.toggle($event)"
                >?</span
            >
            <p-menu #menu [model]="accountMenuItems" [popup]="true" />
            <span class="toggler" (click)="sidebarShrinked = !sidebarShrinked">
                <i class="pi pi-angle-left"></i>
            </span>
        </div>
    </div>
    <div class="main-content">
        <div class="container">
            <div *ngIf="refreshService.showUpdate" class="pt-3">
                <app-update-message></app-update-message>
            </div>
            <div
                class="pb-5"
                [class.pt-5]="!refreshService.showUpdate"
                [class.pt-3]="refreshService.showUpdate">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
