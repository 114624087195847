import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { FeatureFlagsService } from '../services/feature-flags.service';

export function legacyAppGuard(): CanActivateFn {
    return async () => {
        const featureFlagsService = inject(FeatureFlagsService);
        const router = inject(Router);

        if (featureFlagsService.useDotNetBackEnd) {
            await router.navigateByUrl('/');
            return false;
        }

        return true;
    };
}
