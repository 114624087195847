import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from 'src/app/core/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
    templateUrl: './legacy-layout.component.html',
})
export class LegacyLayoutComponent implements OnInit {
    baseUrl = environment.rubyPortalBaseUrl;
    navItems: MenuItem[] = [];
    secondaryNavItems: MenuItem[] = [];

    constructor(private authService: AuthService) { }

    ngOnInit(): void {
        this.navItems = [
            {
                label: 'Record Video',
                routerLink: ['record'],
                visible: this.authService.hasPermission('recording'),
            },
            {
                label: 'Dashboard',
                url: `${environment.rubyPortalBaseUrl}/dashboard`,
                target: '_self',
                visible: this.authService.hasPermission('dashboard'),
            },
            {
                label: 'Reports',
                url: `${environment.rubyPortalBaseUrl}/reports/review_summary`,
                target: '_self',
                visible: this.authService.hasPermission('reporting'),
            },
            {
                separator: true,
            },
            {
                url: `${environment.rubyPortalBaseUrl}/deal_transactions/errors`,
                styleClass: 'app-menuitem-errors',
                target: '_self',
                visible: this.authService.hasPermission('errors'),
            },
            {
                styleClass: 'app-menuitem-help',
                items: [
                    {
                        label: 'Go to the Support Portal',
                        url: 'https://support.go-reahard.com/',
                        target: '_blank',
                    },
                    {
                        label: 'Email Reahard Support',
                        url: 'mailto:support@go-reahard.com?subject=Support Requested',
                        target: '_blank',
                    },
                    {
                        label: 'Push Notifications',
                        routerLink: ['help/push-notifications'],
                        target: '_blank',
                    },
                ],
            },
            {
                label: `Welcome, ${this.authService.user?.firstName}`,
                styleClass: 'app-menuitem-profile',
                items: [
                    {
                        label: 'Account settings',
                        url: `${environment.rubyPortalBaseUrl}/profile`,
                        target: '_self',
                    },
                    {
                        label: 'Log out',
                        command: (): void => void this.authService.logout(),
                        styleClass: 'app-menuitem-signout',
                        iconClass: 'text-light',
                    },
                ],
            },
        ];
    }
}
