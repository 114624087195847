/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { RubyApiProxyService } from '../services/ruby-api-proxy.service';

@Injectable({
    providedIn: 'root',
})
export class BearerTokenInterceptor implements HttpInterceptor {
    private readonly TOKEN_HEADER_KEY = 'Authorization';

    constructor(private authService: AuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        if (
            //non-server API calls don't need bearer tokens
            !req.url.includes('/api/') ||
            //need to call feature flags endpoint before any other server calls so we know how to handle
            //refresh tokens if current auth token is expired
            req.url.includes('/api/feature-flags') ||
            //don't get stuck in infinite refresh token loops
            req.url.includes(RubyApiProxyService.verifyUserUrl) ||
            req.url.includes('/api/auth/refresh-tokens')
        ) {
            return next.handle(req);
        }

        return from(this.addBearerTokenHeader(req, next));
    }

    private async addBearerTokenHeader(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Promise<HttpEvent<any>> {
        const jwt = await this.authService.getJwt();
        const authReq = jwt
            ? req.clone({
                  headers: req.headers.set(
                      this.TOKEN_HEADER_KEY,
                      `Bearer ${jwt}`,
                  ),
              })
            : req;

        return lastValueFrom(next.handle(authReq));
    }
}
