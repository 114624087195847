<div class="site-layout">
    <div class="d-flex flex-column p-4 p-lg-5 bg-secondary-subtle">
        <img class="logo" src="assets/images/logo-blue.svg" />
        <div class="flex-grow-1 d-none d-lg-flex align-items-center">
            <div class="col-content mx-auto">
                <h3 class="fw-medium">
                    Smile! And watch your income increase with F&amp;I
                    Insight&#8482;
                </h3>
                <h6 class="text-light fw-medium">
                    Improve your processes, compliance, and profits. Easy.
                </h6>
            </div>
        </div>
    </div>
    <div class="d-flex align-items-center">
        <div class="form-content mx-auto">
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
