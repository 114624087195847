import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';

@Injectable({ providedIn: 'root' })
export class ErrorModalService {
    constructor(private dialogService: DialogService) {}

    show(message: string, header = 'Error!'): void {
        this.dialogService.open(ErrorModalBodyComponent, {
            data: { message },
            header,
            closable: false,
            styleClass: 'modal-md',
            templates: {
                footer: ErrorModalFooterComponent,
            },
        });
    }

    showForHttpErrorResponse(res: HttpErrorResponse): void {
        switch (res.status) {
            case 401:
                this.show('You must be logged in to perform this action.');
                break;
            case 403:
                this.show(
                    'You do not have the required permissions to perform this action.',
                );
                break;
            case 504:
                this.show('Could not reach server. Please try again later.');
                break;
            default:
                if (
                    res.headers.get('content-type') ===
                    'application/problem+json'
                ) {
                    const reader = new FileReader();
                    reader.onload = (e: ProgressEvent<FileReader>): void => {
                        const problemDetails = JSON.parse(
                            e.target?.result as string,
                        );
                        this.show(problemDetails.detail);
                    };
                    reader.readAsText(res.error);
                } else {
                    this.show('Something went wrong. Please try again later.');
                }
                break;
        }
    }
}

@Component({
    template: ` <div class="text-start">{{ message }}</div> `,
})
class ErrorModalBodyComponent {
    message?: string;

    constructor(dialogConfig: DynamicDialogConfig) {
        this.message = dialogConfig.data?.message;
    }
}

@Component({
    template: `
        <div class="d-grid">
            <button type="button" class="btn btn-primary" (click)="close()">
                OK
            </button>
        </div>
    `,
})
class ErrorModalFooterComponent {
    constructor(private ref: DynamicDialogRef) {}

    close(): void {
        this.ref.close();
    }
}
