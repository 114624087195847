import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'formatedGuid' })
export class FormattedGuidPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(value: string): SafeHtml {
        if (!value) return value;

        // Insert space after every 4th character using regex
        const formattedValue = value
            .replace(/(.{4})/g, '<span>$1</span>')
            .trim();

        return this.sanitizer.bypassSecurityTrustHtml(formattedValue);
    }
}
