import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { filter } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
    selector: 'app-side-bar-menu',
    templateUrl: './side-bar-menu.component.html',
    styleUrl: './side-bar-menu.component.scss',
})
export class SideBarMenuComponent implements OnInit {
    navItems: MenuItem[] | undefined;
    currentRouteUrl = '';

    constructor(
        private authService: AuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.initializeMenu();

        this.router.events
            .pipe(
                filter(
                    (event) =>
                        event instanceof NavigationEnd ||
                        event instanceof NavigationCancel,
                ),
            )
            .subscribe(() => this.initializeMenu());
    }

    initializeMenu(): void {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0];
        const items: MenuItem[] = [
            {
                label: 'Home',
                routerLink: '/main/home',
            },
            {
                label: 'Dashboard',
                routerLink: '/main/dashboard',
                visible: this.authService.hasPermission('dashboard'),
            },
            {
                label: 'Record Video',
                routerLink: '/main/record',
                visible: this.authService.hasPermission('recording'),
            },
            {
                label: 'Recordings',
                routerLink: '/main/recordings',
                visible: this.authService.hasPermission('recording'),
            },
            {
                label: 'Reports',
                routerLink: '/main/reports/review_summary',
                visible: this.authService.hasPermission('reporting'),
            },
            {
                label: 'Admin Settings',
                visible:
                    this.authService.hasPermission('dealershipmanagement') ||
                    this.authService.hasPermission('usermanagement'),
                expanded: true,
                items: [
                    {
                        label: 'Checklists',
                        routerLink: '/main/admin/checklists',
                    },
                    {
                        label: 'Dealer Groups',
                        visible: this.authService.hasPermission(
                            'dealershipmanagement',
                        ),
                        routerLink: '/main/admin/dealer-groups',
                    },
                    {
                        label: 'Dealerships',
                        visible: this.authService.hasPermission(
                            'dealershipmanagement',
                        ),
                        routerLink: '/main/admin/dealerships',
                    },
                    {
                        label: 'Regions',
                        routerLink: '/main/admin/regions',
                    },
                    {
                        label: 'Roles',
                        routerLink: '/main/admin/roles',
                    },
                    {
                        label: 'Users',
                        visible:
                            this.authService.hasPermission('usermanagement'),
                        routerLink: '/main/admin/users',
                    },
                ],
            },
            {
                label: 'Support',
                routerLink: '/main/help/push-notifications',
            },
        ];

        this.navItems = items
            .filter((menuItem) => menuItem.visible ?? true)
            .map((menuItem) => {
                menuItem.routerLinkActiveOptions = {
                    isActive: this.isMenuItemActive(menuItem),
                };
                if (menuItem.items) {
                    menuItem.items = menuItem.items
                        .filter((subItem) => subItem.visible ?? true)
                        .map((subItem) => {
                            subItem.routerLinkActiveOptions = {
                                isActive: this.isMenuItemActive(subItem),
                            };

                            return subItem;
                        });
                }
                // If there is only one child item and it's a group, then replace main item with the only child
                return menuItem.items?.length === 1
                    ? menuItem.items[0]
                    : menuItem;
            });
    }

    isMenuItemActive(item: MenuItem): boolean {
        if (item.items?.length) {
            return this.isMenuRootItemActive(item.items);
        }

        if (!item.routerLink) {
            return false;
        }

        const urlTree = this.router.parseUrl(
            this.currentRouteUrl.replace(/\/$/, ''),
        );
        const urlString =
            '/' +
            urlTree.root.children['primary'].segments
                .map((segment) => segment.path)
                .join('/');
        const exactMatch = urlString === item.routerLink.replace(/\/$/, '');
        return exactMatch;
    }

    isMenuRootItemActive(items: MenuItem[]): boolean {
        let result = false;

        for (const subItem of items) {
            result = this.isMenuItemActive(subItem);
            if (result) {
                return true;
            }
        }

        return false;
    }
}
