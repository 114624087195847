/* eslint-disable @typescript-eslint/no-explicit-any */
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RefreshService } from '../services/refresh.service';

@Injectable({
    providedIn: 'root',
})
export class RefreshSignalInterceptor implements HttpInterceptor {
    constructor(private refreshService: RefreshService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler,
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap((res) => {
                if (res instanceof HttpResponse && req.url.includes('/api/')) {
                    const refreshSignalValue =
                        res.headers.get('X-Refresh-Signal');
                    this.refreshService.setRefreshSignalValue(
                        refreshSignalValue ?? '',
                    );
                }
            }),
        );
    }
}
