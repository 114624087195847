import { DBConfig } from 'ngx-indexed-db';

export const IpCamerasStore = 'ipCameras';
export const PendingUploadsStore = 'pendingUploads';
export const SelectedMediaDevicesStore = 'selectedMediaDevices';

export const DbConfig: DBConfig = {
    name: 'FI Insight',
    //Increment this version whenever schema changes to automatically apply migrations on initialization
    version: 2,
    objectStoresMeta: [
        {
            store: IpCamerasStore,
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [
                {
                    name: 'label',
                    keypath: 'label',
                    options: { unique: true },
                },
                {
                    name: 'url',
                    keypath: 'url',
                    options: { unique: false },
                },
                {
                    name: 'username',
                    keypath: 'username',
                    options: { unique: false },
                },
                {
                    name: 'password',
                    keypath: 'password',
                    options: { unique: false },
                },
            ],
        },
        {
            store: PendingUploadsStore,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                {
                    name: 'uploadId',
                    keypath: 'uploadId',
                    options: { unique: true },
                },
                {
                    name: 'completedParts',
                    keypath: 'completedParts',
                    options: { unique: false },
                },
                {
                    name: 'pendingData',
                    keypath: 'pendingData',
                    options: { unique: false },
                },
            ],
        },
        {
            store: SelectedMediaDevicesStore,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                {
                    name: 'audioDeviceId',
                    keypath: 'audioDeviceId',
                    options: { unique: false },
                },
                {
                    name: 'videoDeviceId',
                    keypath: 'videoDeviceId',
                    options: { unique: false },
                },
            ],
        },
    ],
};
