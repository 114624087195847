import { Component } from '@angular/core';
import { TelemetryService } from './core/services/telemetry.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent {
    constructor(private telemetryService: TelemetryService) {}
}
