import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import {
    GetAwsCredentialsResponse,
    RubyApiProxyService,
} from 'src/app/core/services/ruby-api-proxy.service';

@Injectable({
    providedIn: 'root',
})
export class AwsAuthService {
    private credentials: GetAwsCredentialsResponse | undefined;
    private credentialsExpiration: DateTime | undefined;

    get credentialsExpired(): boolean {
        return (
            !this.credentialsExpiration ||
            this.credentialsExpiration <= DateTime.local()
        );
    }

    constructor(private rubyApiProxyService: RubyApiProxyService) {}

    async getCredentials(): Promise<GetAwsCredentialsResponse> {
        if (!this.credentials || this.credentialsExpired) {
            this.credentials =
                await this.rubyApiProxyService.getAwsCredentials();
            this.credentialsExpiration = DateTime.fromISO(
                this.credentials.expiration,
            );
        }

        return this.credentials;
    }
}
