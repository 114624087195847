<div *ngIf="show && control && control.errors" class="validation-message">
    @if (customMessage) {
        {{ customMessage }}
    } @else if (control.errors['required']) {
        This field is required.
    } @else if (control.errors['email']) {
        Please enter a valid email.
    } @else if (control.errors['maxlength']) {
        Cannot exceed
        {{ control.errors['maxlength']['requiredLength'] }} characters.
    }
</div>
