import { Injectable } from '@angular/core';
import { LoggingService } from './logging.service';
import { PendingUpload } from './data-storage.service';
import { AuthService } from 'src/app/core/services/auth.service';
import {
    AbortMultipartUploadCommand,
    CompleteMultipartUploadCommand,
    CompletedPart,
    CreateMultipartUploadCommand,
    GetObjectCommand,
    PutObjectCommand,
    S3Client,
    UploadPartCommand,
} from '@aws-sdk/client-s3';
import { fromCognitoIdentity } from '@aws-sdk/credential-providers';
import { environment } from 'src/environments/environment';
import { sleepUntil } from '../../shared/utils/thread-utils';
import { AwsAuthService } from './aws-auth.service';
import { Buffer } from 'buffer';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { Duration } from 'luxon';

@Injectable({
    providedIn: 'root',
})
export class AwsS3Service {
    public static INSTALLER_EXPIRY_SECONDS = Duration.fromObject({
        hours: 12,
    }).as('seconds');

    private s3Client: S3Client | undefined;

    private _completedParts: CompletedPart[] = [];
    private _uploadKey: string | undefined;
    private _uploadId: string | undefined;
    private _uploadPending = false;

    get completedParts(): CompletedPart[] {
        return this._completedParts;
    }
    get uploadId(): string | undefined {
        return this._uploadId;
    }
    get uploadPending(): boolean {
        return this._uploadPending;
    }

    constructor(
        private authService: AuthService,
        private awsAuthService: AwsAuthService,
        private loggingService: LoggingService,
    ) {}

    async createUpload(uploadKey: string): Promise<void> {
        this._uploadKey = uploadKey;
        this._uploadId = await this.createMultipartUpload();
    }

    getObjectKey(
        dealTransactionId: number,
        extension: 'png' | 'mp4' | 'webm',
    ): string {
        if (!this.authService.user) {
            throw Error('User is not authenticated!');
        }
        return `user-${this.authService.user.id}/fi-insight-${dealTransactionId}.${extension}`;
    }

    async uploadData(data: Blob, isLastPart = false): Promise<void> {
        if (data.size === 0) {
            return;
        }

        // If recording has ended, wait until any previous uploads are complete
        if (isLastPart) {
            await sleepUntil(() => !this._uploadPending);
        }

        this._uploadPending = true;

        try {
            if (!this._uploadId) {
                this._uploadId = await this.createMultipartUpload();
            }

            const currentPartNumber = this.getCurrentPartNumber();
            await this.uploadPart(data, currentPartNumber);

            if (isLastPart) {
                await this.completeMultipartUpload();
            }
        } finally {
            this._uploadPending = false;
        }
    }

    async uploadThumbnailImage(
        base64Image: string,
        objectKey: string,
    ): Promise<void> {
        try {
            const buffer = Buffer.from(
                base64Image.replace(/^data:image\/\w+;base64,/, ''),
                'base64',
            );
            const s3Client = await this.getS3Client();
            await s3Client.send(
                new PutObjectCommand({
                    Body: buffer,
                    Bucket: environment.aws.s3Bucket,
                    Key: objectKey,
                    ContentType: 'image/png',
                }),
            );
            this.loggingService.log('Uploaded thumbnail image to S3');
        } catch (err) {
            console.error(err);
            this.loggingService.log('Failed to upload thumbnail image to S3');
        }
    }

    async getMsiFileName(): Promise<string | null> {
        const s3Client = await this.getS3Client();

        const command = new GetObjectCommand({
            Bucket: environment.aws.s3InstallersBucket,
            Key: `${environment.aws.s3InstallerObjectKeyPrefix}/installer.txt`,
        });
        const response = await s3Client.send(command);
        if (response.Body === undefined) {
            return null;
        }

        return (await response.Body.transformToString()).trimEnd();
    }

    async getPresignedUrl(
        fileName: string,
        expiry = AwsS3Service.INSTALLER_EXPIRY_SECONDS,
    ): Promise<string> {
        const s3Client = await this.getS3Client();
        const command = new GetObjectCommand({
            Bucket: environment.aws.s3InstallersBucket,
            Key: `${environment.aws.s3InstallerObjectKeyPrefix}/${fileName}`,
        });
        return await getSignedUrl(s3Client, command, { expiresIn: expiry }); // URL expires in 12 hours
    }

    private async createMultipartUpload(): Promise<string | undefined> {
        const s3Client = await this.getS3Client();
        const result = await s3Client.send(
            new CreateMultipartUploadCommand({
                Bucket: environment.aws.s3Bucket,
                Key: this._uploadKey,
            }),
        );
        this.loggingService.log(
            `S3 multipart upload started with upload ID: ${result.UploadId}`,
        );
        return result.UploadId;
    }

    private async uploadPart(data: Blob, partNumber: number): Promise<void> {
        const megabytes = data.size / 1000000;
        this.loggingService.log(
            `Starting part ${partNumber} upload (${megabytes} MB)`,
        );

        const s3Client = await this.getS3Client();
        const result = await s3Client.send(
            new UploadPartCommand({
                Body: data,
                Bucket: environment.aws.s3Bucket,
                Key: this._uploadKey,
                PartNumber: partNumber,
                UploadId: this._uploadId,
            }),
        );

        this.completedParts.push({
            ETag: result.ETag,
            PartNumber: partNumber,
        });

        this.loggingService.log(`Part ${partNumber} uploaded successfully`);
    }

    private async completeMultipartUpload(): Promise<void> {
        const s3Client = await this.getS3Client();
        await s3Client.send(
            new CompleteMultipartUploadCommand({
                Bucket: environment.aws.s3Bucket,
                Key: this._uploadKey,
                MultipartUpload: {
                    Parts: this.completedParts,
                },
                UploadId: this._uploadId,
            }),
        );
        this.loggingService.log('S3 multipart upload completed successfully');
    }

    private getCurrentPartNumber(): number {
        const completedPartNumbers = this.completedParts.map(
            (p) => p.PartNumber ?? 0,
        );
        if (!completedPartNumbers.length) {
            return 1;
        }
        return Math.max(...completedPartNumbers) + 1;
    }

    async abortUpload(): Promise<void> {
        const s3Client = await this.getS3Client();
        await s3Client.send(
            new AbortMultipartUploadCommand({
                Bucket: environment.aws.s3Bucket,
                Key: this._uploadKey,
                UploadId: this._uploadId,
            }),
        );
        this.loggingService.log('S3 multipart upload aborted');
    }

    resetUpload(): void {
        this._uploadKey = undefined;
        this._uploadId = undefined;
        this._completedParts = [];
    }

    restorePendingUpload(pendingUpload: PendingUpload): void {
        this._uploadKey = pendingUpload.uploadKey;
        this._uploadId = pendingUpload.uploadId;
        this._completedParts = pendingUpload.completedParts ?? [];
    }

    private async getS3Client(): Promise<S3Client> {
        return this.s3Client && !this.awsAuthService.credentialsExpired
            ? this.s3Client
            : await this.refreshS3Client();
    }

    private async refreshS3Client(): Promise<S3Client> {
        const oldClient = this.s3Client;

        const credentials = await this.awsAuthService.getCredentials();
        this.s3Client = new S3Client({
            region: environment.aws.region,
            credentials: fromCognitoIdentity({
                clientConfig: { region: environment.aws.region },
                identityId: credentials.identity_id,
                logins: {
                    'cognito-identity.amazonaws.com': credentials.token,
                },
            }),
        });

        oldClient?.destroy();
        return this.s3Client;
    }
}
