import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AwsAuthService } from './aws-auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IpCameraInfo, MediaDevice } from '../models/media-device';
import { AwsS3Service } from './aws-s3.service';

export interface WindowsServiceInfo {
    version: string;
    machineName: string;
}

@Injectable({
    providedIn: 'root',
})
export class WindowsWorkerService {
    public static readonly baseUrl = 'http://localhost:4501';
    private static readonly configureUrl = `${WindowsWorkerService.baseUrl}/configure/`;
    private static readonly getInfoUrl = `${WindowsWorkerService.baseUrl}/get-info/`;
    private static readonly keepRecordingUrl = `${WindowsWorkerService.baseUrl}/keep-recording/`;
    private static readonly startRecordingUrl = `${WindowsWorkerService.baseUrl}/start-recording/`;
    private static readonly stopRecordingUrl = `${WindowsWorkerService.baseUrl}/stop-recording/`;
    private static readonly uploadUrl = `${WindowsWorkerService.baseUrl}/upload/`;

    private readonly isInstalled = new BehaviorSubject<boolean | null>(null);
    readonly isInstalled$ = this.isInstalled.asObservable();
    private readonly serviceInfo =
        new BehaviorSubject<WindowsServiceInfo | null>(null);
    readonly serviceInfo$ = this.serviceInfo.asObservable();

    constructor(
        private awsAuthService: AwsAuthService,
        private awsS3Service: AwsS3Service,
        private httpClient: HttpClient,
    ) {}

    async initialize(): Promise<void> {
        const res = await this.getInfo();
        this.isInstalled.next(!!res);
        this.serviceInfo.next(res);
        if (res) {
            await this.stopRecording();
            await this.uploadPendingFiles();
        }
    }

    async configureIpCamera(info: IpCameraInfo): Promise<void> {
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.configureUrl, {
                url: info.url,
                username: info.username,
                password: info.password,
            }),
        );
    }

    private async getInfo(): Promise<WindowsServiceInfo | null> {
        try {
            return await firstValueFrom(
                this.httpClient.post<WindowsServiceInfo>(
                    WindowsWorkerService.getInfoUrl,
                    {},
                ),
            );
        } catch {
            return null;
        }
    }

    async keepRecording(): Promise<void> {
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.keepRecordingUrl, {}),
        );
    }

    async startRecording(
        dealTransactionId: number,
        selectedAudioDevice: MediaDevice | null,
    ): Promise<void> {
        const objectKey = this.awsS3Service.getObjectKey(
            dealTransactionId,
            'mp4',
        );
        const audioDevice =
            !selectedAudioDevice || selectedAudioDevice.isIpCamera
                ? null
                : selectedAudioDevice.info.label;
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.startRecordingUrl, {
                bucket: environment.aws.s3Bucket,
                objectKey,
                audioDevice,
            }),
        );
    }

    async stopRecording(): Promise<void> {
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.stopRecordingUrl, {}),
        );
    }

    async uploadPendingFiles(): Promise<void> {
        const credentials = await this.awsAuthService.getCredentials();
        await firstValueFrom(
            this.httpClient.post(WindowsWorkerService.uploadUrl, {
                awsIdentityId: credentials.identity_id,
                awsCognitoToken: credentials.token,
                awsRegion: environment.aws.region,
            }),
        );
    }
}
