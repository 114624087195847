import { Injectable, OnDestroy } from '@angular/core';
import { RubyApiProxyService } from 'src/app/core/services/ruby-api-proxy.service';
import { Subject, takeUntil, withLatestFrom } from 'rxjs';
import { DealContextService } from './deal-context.service';

@Injectable({
    providedIn: 'root',
})
export class LoggingService implements OnDestroy {
    private readonly logEntry$ = new Subject<string>();
    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        private dealContextService: DealContextService,
        private rubyApiProxyService: RubyApiProxyService,
    ) {
        this.logEntry$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                withLatestFrom(this.dealContextService.deal$),
            )
            .subscribe(([logEntry, deal]) => {
                console.log(logEntry);
                if (deal) {
                    void this.rubyApiProxyService.addLogEntry({
                        deal_transaction_id: deal.dealTransactionId,
                        description: logEntry,
                    });
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    log(message: string): void {
        this.logEntry$.next(message);
    }
}
