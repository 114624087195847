<div class="alert text-bg-primary d-flex align-items-center">
    <i class="pi pi-exclamation-circle"></i>
    <div class="ms-2">
        A new update is available,
        <a href="javascript:window.location.reload()" class="link-secondary"
            ><u>click here</u></a
        >
        to refresh. Please note: You will lose any unsaved changes.
    </div>
</div>
