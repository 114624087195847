import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-validation-message',
    templateUrl: './validation-message.component.html',
})
export class ValidationMessageComponent {
    @Input() control?: FormControl;
    @Input() customMessage?: string;
    @Input() triggerWhenTouched = true;

    get show(): boolean {
        return (
            !!this.control &&
            this.control.invalid &&
            ((this.triggerWhenTouched && this.control.touched) ||
                (!this.triggerWhenTouched && this.control.dirty))
        );
    }
}
