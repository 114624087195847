import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

export function mfaSetupGuard(requireCompletedSetup: boolean): CanActivateFn {
    return async () => {
        const authService = inject(AuthService);
        const router = inject(Router);

        if (
            !authService.user ||
            (!requireCompletedSetup && authService.user.mfaSetupComplete)
        ) {
            await router.navigateByUrl('/');
            return false;
        }

        if (requireCompletedSetup && !authService.user.mfaSetupComplete) {
            await router.navigateByUrl('/auth/setup');
            return false;
        }

        return true;
    };
}
