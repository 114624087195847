import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-filter-input',
    templateUrl: './filter-input.component.html',
})
export class FilterInputComponent implements OnInit, OnDestroy {
    @Input() filterText = '';
    @Output() filterTextChange = new EventEmitter<string>();

    filterForm = this.formBuilder.group({
        filter: new FormControl(this.filterText),
    });

    get filterControl(): FormControl<string | null> {
        return this.filterForm.controls.filter;
    }

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(private formBuilder: FormBuilder) {}

    ngOnInit(): void {
        this.filterControl.valueChanges
            .pipe(takeUntil(this.unsubscribeSubject$), debounceTime(500))
            .subscribe((val) => this.emitChange(val ?? ''));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    submit(): void {
        this.emitChange(this.filterControl.value ?? '');
    }

    private emitChange(val: string): void {
        if (val === this.filterText) {
            return;
        }

        this.filterText = val;
        this.filterTextChange.emit(val);
    }
}
