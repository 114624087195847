import { Component, Input } from '@angular/core';
import { Int32NameValuePair } from '../../service-proxies/service-proxies';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-filter-multiselect',
    templateUrl: './filter-multiselect.component.html',
})
export class FilterMultiselectComponent {
    @Input() selectOptions: Int32NameValuePair[] = [];
    @Input() selectLabel: string = '';
    @Input() formGroup!: FormGroup;
    @Input() formField!: string;
}
