<div class="legacy-menu">
    <div class="bg-dark" style="flex-grow: 1">
        <p-menubar [model]="navItems" role="navigation">
            <ng-template pTemplate="start">
                <a [href]="baseUrl"
                    ><img
                        class="app-mainmenu-logo"
                        src="assets/images/logo.svg"
                        alt="logo"
                        height="50"
                /></a>
            </ng-template>
        </p-menubar>
    </div>
</div>
<div class="container py-5">
    <router-outlet></router-outlet>
</div>
