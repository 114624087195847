import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface DealInfo {
    customerName: string;
    dealTransactionId: number;
}

@Injectable({
    providedIn: 'root',
})
export class DealContextService {
    private readonly deal = new BehaviorSubject<DealInfo | null>(null);
    readonly deal$ = this.deal.asObservable();

    clearDeal(): void {
        this.deal.next(null);
    }

    setDeal(deal: DealInfo): void {
        this.deal.next(deal);
    }
}
