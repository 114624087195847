import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    NgxGoogleAnalyticsModule,
    provideGoogleAnalytics,
    provideGoogleAnalyticsRouter,
} from '@hakimio/ngx-google-analytics';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { provideClarity } from 'ngx-clarity';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './core/services/global-error-handler.service';
import { DbConfig } from './modules/recording/config/db-config';
import { SharedModule } from './modules/shared/shared.module';
import { FeatureFlagsService } from './core/services/feature-flags.service';
import { AuthService } from './core/services/auth.service';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { BearerTokenInterceptor } from './core/interceptors/bearer-token.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { RefreshSignalInterceptor } from './core/interceptors/refresh-signal.interceptor';

function initializeApp(
    authService: AuthService,
    featureFlagsService: FeatureFlagsService,
) {
    return async (): Promise<void> => {
        await featureFlagsService.init();
        await authService.init();
    };
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularSvgIconModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DynamicDialogModule,
        HttpClientModule,
        NgxGoogleAnalyticsModule,
        NgxIndexedDBModule.forRoot(DbConfig),
        SharedModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AuthService, FeatureFlagsService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshSignalInterceptor,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BearerTokenInterceptor,
            multi: true,
        },
        provideClarity({
            enabled: !!environment.clarity.projectId,
            projectId: environment.clarity.projectId ?? '',
        }),
        provideGoogleAnalytics(environment.googleAnalytics.measurementId ?? ''),
        provideGoogleAnalyticsRouter(),
        DialogService,
        MessageService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
