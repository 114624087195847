<ul class="nav flex-column" role="navigation">
    <ng-container
        *ngFor="let item of navItems"
        [ngTemplateOutlet]="menuItem"
        [ngTemplateOutletContext]="{ item: item }"></ng-container>
</ul>

<ng-template #menuItem let-item="item">
    <li
        class="nav-item"
        [class.has-submenu]="item.items?.length"
        [class.has-active]="
            item.items?.length && item.routerLinkActiveOptions.isActive
        ">
        <a
            *ngIf="!item.items?.length && !item.url"
            [routerLink]="item.routerLink"
            class="nav-link"
            [class.active]="item.routerLinkActiveOptions.isActive">
            {{ item.label }}
        </a>

        <a
            *ngIf="item.items?.length"
            href="javascript:;"
            class="nav-link has-submenu"
            [class.expanded]="item.expanded"
            (click)="item.expanded = !item.expanded">
            {{ item.label }}
            <i class="pi pi-angle-up"></i>
        </a>
        <ul class="nav flex-column" *ngIf="item.items?.length && item.expanded">
            <ng-container
                *ngFor="let subItem of item.items"
                [ngTemplateOutlet]="menuItem"
                [ngTemplateOutletContext]="{ item: subItem }"></ng-container>
        </ul>
    </li>
</ng-template>
