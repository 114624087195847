import { Injectable, OnDestroy } from '@angular/core';
import {
    BehaviorSubject,
    combineLatest,
    filter,
    Subject,
    takeUntil,
} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RefreshService implements OnDestroy {
    private initialRefreshSignalValue: string | null = null;

    private readonly appBusy$ = new BehaviorSubject<boolean>(false);
    private readonly updateAvailable$ = new BehaviorSubject<boolean>(false);
    private readonly unsubscribeSubject$ = new Subject<void>();

    private _showUpdate = false;
    get showUpdate(): boolean {
        return this._showUpdate;
    }

    constructor() {
        combineLatest([this.updateAvailable$, this.appBusy$])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(
                    ([updateAvailable, isBusy]) => updateAvailable && !isBusy,
                ),
            )
            .subscribe(() => (this._showUpdate = true));
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    setAppBusy(isBusy: boolean): void {
        this.appBusy$.next(isBusy);
    }

    setRefreshSignalValue(value: string): void {
        if (this.initialRefreshSignalValue === null) {
            this.initialRefreshSignalValue = value;
        } else if (value !== this.initialRefreshSignalValue) {
            this.updateAvailable$.next(true);
        }
    }
}
