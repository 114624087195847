export const environment = {
    production: false,
    appInsights: {
        instrumentationKey: '4e7d9e58-cc80-4d75-bc59-6a60221e1c4e',
    },
    aws: {
        region: 'us-east-2',
        s3Bucket: 'fi-insight-videos-dev',
        s3InstallersBucket: 'fi-insight-installers',
        s3InstallerObjectKeyPrefix: 'dev',
    },
    clarity: {
        projectId: 'mj73oh66u6',
    },
    googleAnalytics: {
        measurementId: 'G-L01NC2KP2T',
    },
    rubyPortalBaseUrl: 'https://staging.fi-insight.com',
};
