import { ErrorHandler, Injectable } from '@angular/core';
import { TelemetryService } from './telemetry.service';

@Injectable({ providedIn: 'root' })
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private telemetryService: TelemetryService) {}

    handleError(error: Error): void {
        console.error(error);
        this.telemetryService.logException(error);
    }
}
